import HttpRequest from '@/resources/axios/core/http-request'

class AuthProvider extends HttpRequest {
  signUpWithUsernamePassword = (payload) => {
    this.setHeader({
      key: 'Authorization',
      value: 'Bearer cmVzY3VlLXN0YXRpb24tbWFuYWdlbWVudA=='
    })
    return this.post('/auth/sign-up', payload)
  }

  signUpWithSocial = (payload) => {
    this.setHeader({
      key: 'Authorization',
      value: 'Bearer cmVzY3VlLXN0YXRpb24tbWFuYWdlbWVudA=='
    })
    return this.post('/auth/sign-up', payload)
  }

  loginWithEmail = (payload) => {
    this.setHeader({
      key: 'Authorization',
      value: 'Bearer cmVzY3VlLXN0YXRpb24tbWFuYWdlbWVudA=='
    })
    return this.post('/auth/token', payload)
  }

  loginWithSocial = (payload) => {
    this.setHeader({
      key: 'Authorization',
      value: 'Bearer cmVzY3VlLXN0YXRpb24tbWFuYWdlbWVudA=='
    })
    return this.post('/auth/token', payload)
  }

  logout = (payload, config) => this.delete2(
    '/users/sign_out',
    { payload },
    config
  )
}

export default new AuthProvider()
