import firebase from 'firebase'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyCZMv984SMye6xluVXwjcfnEKc28-zjv2A',
  authDomain: 'emergency-location-321611.firebaseapp.com',
  projectId: 'emergency-location-321611',
  storageBucket: 'emergency-location-321611.appspot.com',
  messagingSenderId: '109659297392',
  appId: '1:109659297392:web:5ee7d9f719d2d26004c6b5',
  measurementId: 'G-80ZLZKESYL'
}

const app = firebase

if (firebase.apps.length === 0) {
  firebase.initializeApp(config)
}

const provider = new firebase.auth.GoogleAuthProvider()
// provider.addScope('https://www.googleapis.com/auth/contacts.readonly')

export default {
  provider,
  auth: app.auth()
}
